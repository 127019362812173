<template>
  <ui-component-modal
    modalTitle="Edit option"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :hideSaveButton="optionIsDeleted"
    :onClickSave="saveCartOption"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="savingSuccessMessage"
    :savingErrorMessage="savingErrorMessage"
    :isSavingError="isSavingError"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <section v-if="!isSavingSuccess && !isSavingError" class="section">
        <div class="container">
          <article
            v-if="!isSaving && optionHasChanged"
            class="message is-warning"
          >
            <div class="message-body">
              {{
                $t(
                  'Components.Carts.EditCartOption.Notification_Option-has-changed'
                )
              }}
              <div v-if="amountHasChanged">
                <a @click="updateAmount">Update amount</a>
              </div>
              <div v-if="priceHasChanged">
                <a @click="updatePrice">Update price</a>
              </div>
            </div>
          </article>

          <article v-if="optionIsDeleted" class="message is-danger">
            <div class="message-body">
              {{
                $t(
                  'Components.Carts.EditCartOption.Notification_Option-has-been-deleted'
                )
              }}
            </div>
          </article>

          <table class="table is-fullwidth is-striped is-narrow">
            <tbody>
              <tr>
                <td>Option</td>
                <td class="has-text-right">{{ mOption.OptionName }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Carts.EditCartOption.Table_Amount') }}
                </td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="mOption.Amount"
                        :disabled="optionIsDeleted"
                        class="input"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static">X</a>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Components.Carts.EditCartOption.Table_Price') }}</td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="mOption.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        :disabled="optionIsDeleted"
                        @change="updateCrcValue"
                        @keyup="delay(updateCrcValue, 200)"
                        v-model.number="mOption.PricePerItem"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Components.Carts.EditCartOption.Table_Tax') }}</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      v-model="mOption.TaxPercentage"
                      :disabled="optionIsDeleted"
                    >
                      <option
                        v-for="taxPercentage in taxPercentages"
                        :key="taxPercentage"
                        :value="taxPercentage"
                        :selected="taxPercentage === mOption.TaxPercentage"
                      >
                        {{ taxPercentage }}%
                      </option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr v-if="mOption.TimeSelectable > 0">
                <td>Time</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      v-model="mOption.SelectedTime"
                      :disabled="optionIsDeleted"
                    >
                      <option
                        :value="-1"
                        :selected="mOption.SelectedTime === -1"
                      >
                        {{
                          $t(
                            'Components.Carts.EditCartOption.Select_Option_Time-non-applicable'
                          )
                        }}
                      </option>
                      <option
                        v-for="timeSlot in timeSlots"
                        :key="timeSlot"
                        :value="timeSlot"
                        :selected="mOption.SelectedTime === timeSlot"
                      >
                        {{ timeSlot | minutesToTime }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr>
                <td>Space</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      v-if="cart.Spaces.length > 0"
                      :disabled="optionIsDeleted"
                      class="input"
                      v-model="mOption.SpaceId"
                    >
                      <option :selected="mOption.SpaceId === 0" :value="0">
                        {{
                          $t(
                            'Components.Carts.EditCartOption.Select_Option_Space-non-applicable'
                          )
                        }}
                      </option>
                      <optgroup label="--Booked space(s)--">
                        <option
                          v-for="(cartSpace, index) in cart.Spaces"
                          :key="index"
                          :selected="mOption.SpaceId === cartSpace.SpaceId"
                          :value="cartSpace.SpaceId"
                        >
                          {{ cartSpace.SpaceName }}
                        </option>
                      </optgroup>
                      <optgroup
                        v-if="spaces.length > 0"
                        label="--Other space(s)--"
                      >
                        <option
                          v-for="(space, index) in spaces"
                          :key="index"
                          :selected="mOption.SpaceId === space.Id"
                          :value="space.Id"
                        >
                          {{ space.Name }}
                        </option>
                      </optgroup>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td class="has-text-right">
                  {{ optionTotalPrice | toCurrency }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import cartProvider from '@/providers/cart'
import crcProvider from '@/providers/crc'
import taxProvider from '@/providers/tax'

export default {
  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    option: {
      default: null,
      type: Object,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      mOption: JSON.parse(JSON.stringify(this.option)),
      spaces: [],
      timeSlots: [],
      verifyOption: JSON.parse(JSON.stringify(this.option)),
      savingSuccessMessage: 'Option saved',
      savingErrorMessage: 'Oops something went wrong',
      timer: null,
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('locationStore', ['locationState']),

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },

    amountHasChanged() {
      let hasChanged = false

      if (this.option && this.verifyOption) {
        if (this.option.Amount !== this.verifyOption.Amount) {
          hasChanged = true
        }
      }

      return hasChanged
    },

    priceHasChanged() {
      let hasChanged = false

      if (this.option && this.verifyOption) {
        if (this.option.PricePerItem !== this.verifyOption.PricePerItem) {
          hasChanged = true
        }
      }

      return hasChanged
    },

    taxHasChanged() {
      let hasChanged = false

      if (this.option && this.verifyOption) {
        if (this.option.TaxId !== this.verifyOption.TaxId) {
          hasChanged = true
        }
      }

      return hasChanged
    },

    optionHasChanged() {
      let hasChanged = false

      if (this.amountHasChanged || this.priceHasChanged || this.taxHasChanged) {
        hasChanged = true
      }

      return hasChanged
    },

    optionIsDeleted() {
      let isDeleted = false

      if (this.option && this.verifyOption && this.option.Id === -1) {
        isDeleted = true
      }

      return isDeleted
    },

    optionTotalPrice() {
      let self = this
      let total = 0.0

      if (self.mOption) {
        total =
          Math.round(
            Number(self.mOption.Amount) *
              Number(self.mOption.PricePerItem) *
              100
          ) / 100
      }

      return total
    },

    resStart() {
      let minStartMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.cart && this.cart.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.cart.Spaces.length - 1; i >= 0; i--) {
          tmp = this.cart.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },
  },

  created() {
    this.fillTimeSlots()
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    fillTimeSlots() {
      let min = this.resStart
      let max = this.resEnd
      let minutes = 30

      let i = min
      while (i <= max) {
        this.timeSlots.push(i)
        i = i + minutes
      }
    },

    updateAmount() {
      this.mOption.Amount = this.option.Amount
      this.verifyOption.Amount = this.option.Amount
    },

    updatePrice() {
      this.mOption.PricePerItem = this.option.PricePerItem
      this.mOption.Crc = this.option.Crc
      this.verifyOption.PricePerItem = this.option.PricePerItem
      this.verifyOption.Crc = this.option.Crc
    },

    delay(callback, ms) {
      let self = this

      if (self.timer) {
        clearTimeout(self.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    updateCrcValue() {
      if (Number(this.mOption.PricePerItem) > 0) {
        crcProvider.methods
          .checkCrc(this.mOption.OptionId, this.mOption.PricePerItem)
          .then((response) => {
            if (response.status === 200) {
              this.mOption.Crc = response.data
            }
          })
      }
    },

    saveCartOption() {
      let self = this

      let updatedOption = self.mOption
      updatedOption.PriceTotal = self.optionTotalPrice

      if (!self.isSaving) {
        self.isSaving = true

        cartProvider.methods
          .saveCartOption(self.cart.CartKey, updatedOption)
          .then((response) => {
            if (response.status === 200) {
              self.setCart(response.data)
              self.isSavingSuccess = true

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Option is saved!',
              })

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
            ////console.log(error.response)
            self.savingErrorMessage =
              error.response.status + ' - ' + error.response.statusText

            EventBus.$emit('showToast', {
              type: 'error',
              message: 'Option not saved!',
            })
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
