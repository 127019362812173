import axios from "axios";

export default {
  methods: {
    async checkCrc(itemId, price) {
      let criteria = {
        ItemId: itemId,
        Price: price
      };

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/crc`,
        JSON.stringify(criteria),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      );
      return response;
    }
  }
};
